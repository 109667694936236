{
  "name": "cookbook",
  "version": "0.1.2",
  "private": true,
  "source": "src/index.html",
  "staticFiles": {
    "staticPath": "public"
  },
  "scripts": {
    "start": "parcel",
    "dev": "parcel",
    "build": "parcel build",
    "test": "jest",
    "test:e2e": "pnpm playwright test",
    "serve": "npx serve -s build",
    "lint": "eslint --max-warnings=0 ./src/**/*.tsx ./public/locales/**/*.json",
    "format": "prettier --check .",
    "commitlint": "commitlint --from=c70a78dd8d08a2d528f0a1d2876070b879e17805",
    "precommit": "pnpm lint && pnpm format && pnpm test && pnpm build"
  },
  "eslintIgnore": [
    "build",
    "node_modules"
  ],
  "simple-git-hooks": {
    "pre-commit": "pnpm precommit"
  },
  "alias": {
    "process": "process/browser.js",
    "buffer": "buffer"
  },
  "browserslist": "> 0.5%, last 2 versions, not dead",
  "dependencies": {
    "@clerk/clerk-react": "^4.23.1",
    "@clerk/types": "^3.31.0",
    "@headlessui/react": "^1.6.2",
    "@heroicons/react": "latest",
    "@notionhq/client": "^1.0.4",
    "@react-spring/web": "^9.7.1",
    "@reduxjs/toolkit": "^1.8.1",
    "@tensorflow-models/handpose": "^0.0.7",
    "@tensorflow/tfjs": "^4.0.0",
    "@types/lodash": "^4.14.188",
    "@types/node": "^16.7.13",
    "@types/react": "^18.0.0",
    "@types/react-dom": "^18.0.0",
    "@types/react-helmet": "^6.1.5",
    "axios": "^0.27.2",
    "classnames": "^2.3.1",
    "clsx": "^1.2.1",
    "file-saver": "^2.0.5",
    "fingerpose": "^0.1.0",
    "firebase": "^9.8.1",
    "framer-motion": "^7.8.0",
    "fuse.js": "^6.6.2",
    "i18next": "^21.8.3",
    "i18next-browser-languagedetector": "^6.1.4",
    "i18next-http-backend": "^1.4.0",
    "jszip": "^3.10.1",
    "lodash": "^4.17.21",
    "lord-icon-element": "^4.0.3",
    "lottie-web": "^5.10.2",
    "mixpanel-browser": "^2.45.0",
    "nosleep.js": "^0.12.0",
    "postcss": "^8.4.23",
    "process": "^0.11.10",
    "react": "^18.2.0",
    "react-dnd": "^16.0.1",
    "react-dnd-html5-backend": "^16.0.1",
    "react-dom": "^18.1.0",
    "react-helmet": "^6.1.0",
    "react-helmet-async": "^1.3.0",
    "react-i18next": "^11.16.9",
    "react-input-autosize": "^3.0.0",
    "react-parallax": "^3.5.1",
    "react-redux": "^8.0.1",
    "react-router-dom": "^6.11.1",
    "react-speech-recognition": "^3.10.0",
    "react-tag-input": "^6.8.0",
    "react-textarea-autosize": "^8.3.4",
    "react-tooltip": "^4.4.3",
    "react-webcam": "^7.0.1",
    "regenerator-runtime": "^0.13.11",
    "tailwind": "^4.0.0",
    "typescript": "^4.4.2",
    "use-debounce": "^9.0.2",
    "uuid": "^8.3.2",
    "web-vitals": "^2.1.0"
  },
  "devDependencies": {
    "@axe-core/playwright": "^4.6.1",
    "@commitlint/cli": "^17.5.1",
    "@commitlint/config-conventional": "^17.4.4",
    "@jest/globals": "^29.5.0",
    "@parcel/config-default": "^2.8.3",
    "@parcel/transformer-svg-react": "^2.8.3",
    "@playwright/test": "^1.32.2",
    "@tailwindcss/aspect-ratio": "^0.4.0",
    "@tailwindcss/forms": "^0.5.2",
    "@testing-library/jest-dom": "^5.17.0",
    "@testing-library/react": "^13.0.0",
    "@testing-library/user-event": "^13.2.1",
    "@types/dom-screen-wake-lock": "^1.0.0",
    "@types/file-saver": "^2.0.5",
    "@types/jest": "^27.5.2",
    "@types/mixpanel-browser": "^2.38.0",
    "@types/react-input-autosize": "^2.2.1",
    "@types/react-speech-recognition": "^3.9.0",
    "@types/react-tag-input": "^6.1.3",
    "@types/uuid": "^8.3.4",
    "@typescript-eslint/eslint-plugin": "^5.59.6",
    "@typescript-eslint/parser": "^5.59.6",
    "brfs": "^2.0.2",
    "buffer": "^5.7.1",
    "eslint": "^8.24.0",
    "eslint-config-prettier": "^8.5.0",
    "eslint-import-resolver-parcel": "^1.10.6",
    "eslint-plugin-i18n-json": "^4.0.0",
    "eslint-plugin-i18next": "next",
    "eslint-plugin-json": "^3.1.0",
    "eslint-plugin-jsx-a11y": "^6.7.1",
    "eslint-plugin-prettier": "^4.2.1",
    "eslint-plugin-react": "^7.32.2",
    "eslint-plugin-sonarjs": "^0.19.0",
    "jest": "^29.5.0",
    "jest-environment-jsdom": "^29.5.0",
    "parcel": "^2.0.0",
    "parcel-reporter-static-files-copy": "^1.5.0",
    "prettier": "^2.6.2",
    "serve-static": "^1.15.0",
    "simple-git-hooks": "^2.8.0",
    "tailwindcss": "^3.3.2",
    "ts-jest": "^29.1.0"
  }
}
